import React from 'react'
import HeroSection from './HeroSection'
const Page = () => {
  return (
    <div className="bg-white">
      <HeroSection/>
      
    </div>
  )
}

export default Page