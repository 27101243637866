import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Testimonial from './Testimonial';
import { ClipLoader } from 'react-spinners'; 


export default function TestimonialPage() {
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState('https://img.icons8.com/ios-glyphs/60/user--v1.png');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    profilePhoto: null,
  });

  const loadFile = (event) => {
    const input = event.target;
    const file = input.files[0];

    if (file) {
      const output = URL.createObjectURL(file);
      setPreviewSrc(output);
      setFormData({ ...formData, profilePhoto: file });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = new FormData();
    form.append('first_name', formData.firstName);
    form.append('last_name', formData.lastName);
    form.append('email', formData.email);
    form.append('message', formData.message);
    if (formData.profilePhoto) {
      form.append('profile_photo', formData.profilePhoto);
    }

    try {
      const response = await fetch('https://admin.executivehaven.org/api/get-all-testimonials/', {
        method: 'POST',
        body: form,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setSuccess(true);
      setLoading(false);

      console.log('Success:', result);
    } catch (error) {
      setErrorMessage(true);

      console.error('Error:', error);
    }
  };

  return (
    <>
      <Header />
      <Testimonial />
      <div className="isolate bg-white px-6 py-12 sm:py-10 lg:px-8">
        <div
          className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
          aria-hidden="true"
        >
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Testimonial</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            We value your feedback! Please take a moment to share your experience with ExecutiveHaven. Your testimonials help us improve our services and provide valuable insights for future clients. Thank you for your time and support!
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
        {success ? (
              <div className="text-center text-green-600 font-semibold mb-5">
                Thank you for submitting your testimonial! Your feedback has been successfully received and is greatly appreciated.
              </div>): ''}
              {errorMessage ? (
              <div className="text-center text-red-600 font-semibold mb-5">
                An Error Occurred.
              </div>): ''}
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-semibold leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  autoComplete="email"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="shrink-0">
                <img id="preview_img" className="h-16 w-16 object-cover rounded-full" src={previewSrc} alt="Current profile photo" />
              </div>
              <label className="block">
                <span className="sr-only">Choose profile photo</span>
                <input
                  required
                  type="file"
                  onChange={loadFile}
                  className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                />
              </label>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                Message
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  required
                  value={formData.message}
                  onChange={handleChange}
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              {loading ? (
                    <div className="text-center my-4">
                      <ClipLoader color="white" loading={loading} size={20}  /> {/* Use ClipLoader spinner */}
                    </div>
                  ): 'Send Testimonial'}
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}
