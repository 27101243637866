import { ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Unparalleled Trust',
    description:
      'Our clients trust us because we treat their business as our own. We are committed to your success, and your trust in us is our greatest reward.',
    href: '#',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Personalized Care',
    description:
      'We go beyond tasks; we care about your goals. Our services are tailored to your unique needs, providing you with the support you deserve.',
    href: '#',
    icon: LockClosedIcon,
  },
  {
    name: 'Expertise Across Industries',
    description:
      'Our team of tech-savvy Virtual Assistants is managed by top industry professionals in Finance, Tech, HR, and more. We understand the nuances of your business.',
    href: '#',
    icon: ArrowPathIcon,
  },
]

export default function ChooseUs() {
  return (
    <div className="bg-white py-16 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Why Choose Us
          </p>
        </div>
        <div className="mx-auto mt-3 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none" style={{color: '#3BC52B'}} aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
