import { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
export default function Affiliate() {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    fetch("https://admin.executivehaven.org/api/all-partners/")
      .then((response) => response.json())
      .then((data) => setPartners(data))
      .catch((error) => console.error("Error fetching partners:", error));
  }, []);
  const logosRef = useRef(null);

  useEffect(() => {
    const ul = logosRef.current;
    if (ul) {
      ul.insertAdjacentHTML("afterend", ul.outerHTML);
      ul.nextSibling.setAttribute("aria-hidden", "true");
    }
  }, []);
  console.log("The Partners", partners);
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2 className="text-lg font-semibold leading-8 text-gray-900">
            Our Trusted Affiliates
          </h2>
          <div
            x-data="{}"
            x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
            class="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
          >
            <ul
              x-ref="logos"
              class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
            >
              {partners.map((partner, index) => (
              <li className="mt-5">
                <img className="h-12 w-12" src={partner.logo} alt="Facebook" />
              </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
