// PostDetails.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { ClipLoader } from 'react-spinners';

const PostDetails = () => {
const { slug } = useParams();
console.log(slug)
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    console.log(post)
  useEffect(() => {
    axios.get(`https://admin.executivehaven.org/api/blog/${slug}/`)
      .then(response => {
        setPost(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center h-screen">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
        <Footer />
      </>
    );
  }
  if (error) return <p>Error loading post: {error.message}</p>;

  return (
    <>
        <Header/>
        <div class="relative p-4">
    <div class="bg-white mx-auto max-w-7xl px-6 lg:px-8 py-18 sm:py-16">
        <div
            class="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
            <div class="">

                <p class="text-green-600 hover:text-gray-700 transition duration-500 ease-in-out text-sm">
                    {post.category}
                </p>
                <h1 className="text-gray-900 mt-3 font-bold text-4xl leading-normal">{post.title}</h1>
                <div class="py-5 text-sm font-regular text-gray-900 flex">
                    <span class="mr-3 flex flex-row items-center">
                       
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="flex flex-row items-center text-green-600 h-6 w-6" >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                      </svg>
                      <span class="ml-1">{post.date}</span>

                    </span>

                    <p class="flex flex-row items-center hover:text-green-600  mr-3">
                        <svg class="text-green-600" fill="currentColor" height="16px" aria-hidden="true" role="img"
                            focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor"
                                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z">
                            </path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                        <span class="ml-1">{post.author}</span></p>
                </div>
                <hr/>
                <div className='mt-10 leading-9' dangerouslySetInnerHTML={{ __html: post.description }} />
            </div>

        </div>
    </div>
</div>
        <Footer/>
    </>
  );
};

export default PostDetails;


