import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  MegaphoneIcon,
  AcademicCapIcon,
  HashtagIcon,
  ChartBarIcon,
  DocumentTextIcon,

} from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Administrative Task',
    description: 'Scheduling and Calendar Management, Data Entry Filing, Travel Arrangements, Meeting Preparation, Communication, Handling Phone Calls, Employee Onboarding, HR Support',
    icon: DocumentTextIcon,
  },
  {
    name: 'Sales And Lead Generation',
    description: 'Prospecting new clients, Conducting product demonstrations, Creating sales presentations Handling customer inquiries Managing sales leads',
    icon: ChartBarIcon,
  },
  {
    name: 'Social Media Management',
    description: 'Schedule daily posts, Create engaging content, Respond to comments and messages, Maintain brand consistency, Research and use relevant hashtags',
    icon: HashtagIcon,
  },
  {
    name: 'Consultancy',
    description: 'Contact us today to schedule a consultation and discover how our virtual assistant consultancy services can help your business thrive',
    icon: AcademicCapIcon,
  },
  {
    name: 'Brand content development and marketing',
    description: `Elevate your brand's presence with our comprehensive services in content development and marketing, designed to create engaging and impactful narratives that resonate with your target audience and drive business growth`,
    icon: MegaphoneIcon,
  },
]

const Services = () => {
  return (
    <>
      <Header />
      <div
        id="services"
        class="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-white"
      >
        <div class="xl:max-w-6xl mx-auto px-4">
          <header class="text-center mx-auto mb-12 lg:px-20">
            <h2 class="text-2xl leading-normal mb-2 mt-20 font-bold text-black">
              What We Do
            </h2>
            {/* <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 60"
              style="margin: 0 auto;height: 35px;"
              xml:space="preserve"
            >
              <circle
                cx="50.1"
                cy="30.4"
                r="5"
                class="stroke-primary"
                style="fill: transparent;stroke-width: 2;stroke-miterlimit: 10;"
              ></circle>
              <line
                x1="55.1"
                y1="30.4"
                x2="100"
                y2="30.4"
                class="stroke-primary"
                style="stroke-width: 2;stroke-miterlimit: 10;"
              ></line>
              <line
                x1="45.1"
                y1="30.4"
                x2="0"
                y2="30.4"
                class="stroke-primary"
                style="stroke-width: 2;stroke-miterlimit: 10;"
              ></line>
            </svg> */}
            <p class="text-gray-500 leading-relaxed font-light text-xl mx-auto pb-2">
            Optimize Your Workflow with Expert Visual Assistance for Your Business.
            </p>
          </header>
          <div className="my-20">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6 new-visible">
                      <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                        <div className="-mt-6">
                          <div className="w-1/6 m-auto">
                            <span className="inline-flex items-center justify-center rounded-md p-3 shadow-lg" style={{backgroundColor :"#3BC52B"}}>
                              <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-center text-gray-900">{feature.name}</h3>
                          <p className="mt-5 text-base text-center text-gray-500">{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
          {/* <div class="flex flex-wrap flex-row -mx-4 text-center">
            <div
              class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp new-visible"
              data-wow-duration="1s"
              // style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;"
            >
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                  </svg>
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-black">
                  Administrative Task
                </h3>
                <p class="text-gray-500">
                Scheduling and Calendar Management, Data Entry Filing, Travel Arrangements, Meeting Preparation, Communication, Handling Phone Calls, Employee Onboarding, HR Support


                </p>
              </div>
            </div>
            <div
              class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp new-visible"
              data-wow-duration="1s"
              data-wow-delay=".1s"
              // style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;"
            >
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    fill="currentColor"
                    class="bi bi-chat-square-dots"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path>
                    <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                  </svg>
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-black">
                  Sales and leading generation
                </h3>
                <p class="text-gray-500">
                Prospecting new clients, Conducting product demonstrations, Creating sales presentations Handling customer inquiries Managing sales leads


                </p>
              </div>
            </div>
            <div
              class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp new-visible"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              // style="visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;"
            >
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    fill="currentColor"
                    class="bi bi-badge-ad"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.7 11l.47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852l.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z"></path>
                    <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"></path>
                  </svg>
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-black">
                  Social Media Management
                </h3>
                <p class="text-gray-500">
                Schedule daily posts, Create engaging content, Respond to comments and messages, Maintain brand consistency, Research and use relevant hashtags


                </p>
              </div>
            </div>
            <div
              class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp new-visible"
              data-wow-duration="1s"
              // style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;"
            >
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    fill="currentColor"
                    class="bi bi-card-checklist"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"></path>
                    <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"></path>
                  </svg>
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-black">
                  Brand Identity
                </h3>
                <p class="text-gray-500">
                  This is a wider card with supporting text below as a natural
                  content.
                </p>
              </div>
            </div>
            <div
              class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp new-visible"
              data-wow-duration="1s"
              data-wow-delay=".1s"
              // style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;"
            >
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    fill="currentColor"
                    class="bi bi-wallet2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"></path>
                  </svg>
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-black">
                  Budget &amp; Marketing
                </h3>
                <p class="text-gray-500">
                  This is a wider card with supporting text below as a natural
                  content.
                </p>
              </div>
            </div>
            <div
              class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp new-visible"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              // style="visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;"
            >
              <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    fill="currentColor"
                    class="bi bi-funnel"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"></path>
                  </svg>
                </div>
                <h3 class="text-lg leading-normal mb-2 font-semibold text-black">
                  Optimize conversions
                </h3>
                <p class="text-gray-500">
                  This is a wider card with supporting text below as a natural
                  content.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
