// src/services/api.js
import axios from 'axios';

const all_testimonial = 'https://admin.executivehaven.org/api/get-all-testimonials/'; 
const featured_testimonial = 'https://admin.executivehaven.org/api/featured-testimonial/'; 



export const fetchAllTestimomials = async () => {
  try {
    const response = await axios.get(all_testimonial);
    return response
  } catch (err) {
    if (err.code === 'ERR_NETWORK') {
        console.log('Network Error');
      } else {
        console.log('An unexpected error occurred');
      }
    console.error('Error fetching posts:', err);
  }
};


export const fetchFeaturedTestimomials = async () => {
    try {
      const response = await axios.get(featured_testimonial);
      return response;
    } catch (err) {
        if (err.code === 'ERR_NETWORK') {
            console.log('Network Error');
          } else {
            console.log('An unexpected error occurred');
        }
      console.error('Error fetching posts:', err);
    }
  };
  